import Slider from "../../../../../node_modules/@tannerhodges/snap-slider/snap-slider.js";

// Handles advancing the How To carousel slides when clicking on the
// anchor links. We don't want the page to scroll down to the element.
document.addEventListener("click", function(event) {
  if (!event.target.matches(`[data-snap-slider] a[href^="#"]`)) {
    return;
  }

  event.preventDefault(); // Note: this stops the hash change.
  const target = document.querySelector(event.target.getAttribute("href"));
  if (target) {
    target.scrollIntoView({
      block: "nearest", // Do not scroll down to the element
      inline: "nearest",
      behavior: "smooth",
    });
  }
});
