window.addEventListener("DOMContentLoaded", () => {

  // Supports closing the modal by clicking outside of it.
  // This works because the modal has an inner container (the form)
  // so the `event.target` would not be the modal when clicking anywhere
  // inside the modal.
  //
  // Here we close the modal with empty `returnValue`.
  document.querySelectorAll("dialog.modal").forEach((modal) => {
    modal.addEventListener("click", (event) => {
      if (event.target === modal) {
        modal.close("");
      }
    });

    modal.addEventListener("reset", () => {
      modal.close("");
    });
  });

  document.querySelectorAll(`a[href^="#"][rel~="lightbox"]`).forEach((trigger) => {
    const href = trigger.getAttribute("href");
    const modal = document.querySelector(`dialog[id="${href.substring(1)}"]`);
    if (modal) {
      trigger.addEventListener("click", (event) => {
        event.preventDefault();
        !modal.open && modal.show();
      });
    }
  });

});

document.querySelectorAll("dialog").forEach(dialog => {
  // Handles "faux" close button using empty anchor to clear the `target:`
  const closeButton = dialog.querySelector(`a[href="#close"`);
  if (closeButton) {
    closeButton.addEventListener("click", function(event) {
      dialog.close();
    });
  }
});

/**
 * Opens faux modal using CSS `target:` to actual modal.
 */
function openModalByHash() {
  const hash = window.location.hash;
  if (hash) {
    const modal = document.querySelector(`dialog[id="${hash.substring(1)}"]`);
    if (modal) {
      !modal.open && modal.show();
    }
  }
}

window.addEventListener("hashchange", openModalByHash, false);
openModalByHash();
