if (document.querySelector("[data-locations='status']")) {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  script.async = true;
  script.defer = true;
  script.onload = () => {
    gapi.load("client", getSheetData)
  };
  document.head.appendChild(script);

  function getSheetData() {
    const SHEETAPIKEY = "AIzaSyAE-7Nmyo9EpqeXTAT0becj7wGI-sc7b_g";
    const SHEETID = "1u25CPlFZDkUxVXueS6isB8wIJWHbgBY_cnZMePy9gJg";

    let sheetData = [];

    gapi.client.init({
      "apiKey": SHEETAPIKEY
    }).then(() => {
      return gapi.client.request({
        "path": "https://sheets.googleapis.com/v4/spreadsheets/" + SHEETID + "/values/Locations!A2:Z"
      });
    }).then(response => {
      if (response.result?.values.length > 0) {
        response.result.values.forEach(sheetLocation => {
          sheetData.push([sheetLocation[0], sheetLocation[4]]);
        });
      }
    }, reason => {
      if (typeof reason === "object" && reason.result) {
        console.error && console.error("Error: " + reason.result.error.message);
      } else {
        console.error && console.error(reason);
      }
    }).then(() => {
      let tables = document.querySelectorAll("[data-locations='status'] tbody");
      let split = Math.ceil(sheetData.length / tables.length);

      tables.forEach((locationsTable, idx) => {
        let start = split * idx;
        let end = split * (idx + 1);

        for (let i = start; i < end; i++) {
          if (!sheetData[i]) return;

          let bankName = sheetData[i][0];
          let bankStatus = sheetData[i][1]
          let statusClass;

          switch (bankStatus) {
            case "Closed":
              statusClass = "bg-tertiary-4 text-white";
              break;
            case "Open":
              statusClass = "bg-secondary-1 bg-opacity-30";
              break;
            default:
              statusClass = "bg-secondary-2";
          }

          locationsTable.innerHTML += `
            <tr>
              <td>${bankName}</td>
              <td class="${statusClass}">${bankStatus}</td>
            </tr>
          `;
        }
      });
    });
  }
}
