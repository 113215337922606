const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const $body = $("body");
const $siteHeader = $(`header[role="banner"]`);

// Observes the site header, which should have `top: -1px`, and toggles
// the `stuck` class when it hits the top of the page.
new IntersectionObserver(
  ([e]) => e.target.classList.toggle("stuck", e.intersectionRatio < 1),
  { threshold: [1] }
).observe($siteHeader);

// Toggles state of the site header when the menu button is clicked.
document.addEventListener("click", function(event) {
  const menu = event.target.closest(`[aria-controls="menu"]`);

  if (menu) {
    const state = menu.getAttribute("aria-expanded");
    const isOpen = state === "true";

    menu.setAttribute("aria-expanded", isOpen ? "false" : "true");
    menu.innerText = isOpen ? "Menu" : "Close";
    $siteHeader.classList.toggle("menu-open");
    $body.classList.toggle("overflow-hidden");
  }

  // On mobile, clicking a primary menu item will close the branch.
  if (event.target.matches(`li.open>button`)) {
    event.target.closest(`li`).classList.toggle("open");
  }
});

/**
 * Toggles a variant on an element.
 * @param {HTMLElement} navItem
 * @param {boolean} force whether to force open
 * @param {string} variant the variant to toggle.
 */
function toggle(navItem, force, variant = "hover:") {
  const classList = navItem.classList;
  classList.forEach(function(className) {
    if (className.indexOf(variant) > -1) {
      classList.toggle(className.replace(variant, ""), force);
    }
  });

  if (classList.contains("group")) {
    const descendants = navItem.querySelectorAll(`[class*="group-${variant}"]`);
    Array.from(descendants).forEach(function(item) {
      toggle(item, force, `group-${variant}`);
    });
  }

  if (classList.contains("peer")) {
    const peers = navItem.querySelectorAll(`:scope+[class*="peer-${variant}"]`);
    Array.from(peers).forEach(function(item) {
      toggle(item, force, `peer-${variant}`);
    });
  }
}

const $primary = document.querySelector(`nav[aria-label="Primary"]`);
const $parents = $primary.querySelectorAll(".isParent");

Array.from($parents).forEach(function($parent) {
  // Handles ARIA for buttons and menus.
  const $toggle = $parent.querySelector(":scope > button");
  if (!$toggle) return;

  $parent.addEventListener("mouseover", function(event) {
    toggle(this, true);
    $toggle.setAttribute("aria-expanded", `true`);
  });

  $parent.addEventListener("mouseout", function(event) {
    toggle(this, false);
    $toggle.setAttribute("aria-expanded", `false`);
  });

  $toggle.addEventListener("click", function(event) {
    const expanded = ($toggle.getAttribute("aria-expanded") === "true");
    $toggle.setAttribute("aria-expanded", `${!expanded}`);

    toggle($parent, !expanded)
  });
});
