const isExternalURL = (url) => {
  if (url.startsWith("javascript:")) {
    return false;
  }

  if (url.startsWith("tel:")) {
    return false;
  }

  if (url.startsWith("mailto:")) {
    return false;
  }

  const origin = new URL(url).origin;
  if (origin === "https://onlinebanking.stellar.bank") {
    return false;
  }

  return origin !== location.origin;
};

window.addEventListener("DOMContentLoaded", () => {
  const speedbump = document.getElementById("speedbump");
  const confirmButton = speedbump.querySelector(`button[type="submit"]`);
  const safeLink = speedbump.querySelector(`.safe`);

  // Intercepts clicks on external links and shows the speedbump modal.
  window.addEventListener("click", function(event) {
    const link = event.target;
    const { href } = link;
    if (!href) return true;
    if (link.closest(".mce-content-body")) return true;
    if (!isExternalURL(href)) return true;
    if (link.classList.contains("safe")) return true;

    event.preventDefault();

    confirmButton.value = href;
    // Hides submit button and shows safe link.
    confirmButton.classList.add("hidden");
    safeLink.href = href;
    safeLink.setAttribute("target", link.getAttribute("target"));
    safeLink.setAttribute("rel", link.getAttribute("rel"));
    safeLink.classList.remove("hidden");

    speedbump.showModal();
  });

  safeLink.addEventListener("click", function(event) {
    confirmButton.form.submit();
  });

  // "Confirm" button of form triggers "close" on dialog because of [method="dialog"]
  speedbump.addEventListener("close", () => {
    const value = speedbump.returnValue;
    confirmButton.value = "";

    // Hides safe link and shows submit button.
    safeLink.classList.add("hidden");
    safeLink.href = "";
    safeLink.removeAttribute("target");
    safeLink.removeAttribute("rel");

    confirmButton.classList.remove("hidden");
  });

});
