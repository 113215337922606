window.addEventListener("DOMContentLoaded", async () => {
  const calculator = document.getElementById("tvcMainCalculatorDivId");
  const container = calculator.querySelector("#tvcInnerCalculatorDivId");

  calculator.addEventListener("reset", () => {
    window.history.back();
  });

  const BASE_URL = new URL("https://www.TimeValueCalculators.com/timevaluecalculators/Calculator2.aspx");
  BASE_URL.searchParams.set("TEMPLATE_ID", "www.stellar.bank_1");
  window.PASSTHROUGH = "";

  /**
   * Shortcut to load a remote script and "eval" it for the calculator HTML.
   * @param {string} url the URL to the script source
   * @returns Promise<void>
   */
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      window.tvcCalculatorHtml = "";
      const script = document.createElement("script");
      script.src = url;
      script.onload = () => {
        container.innerHTML = tvcCalculatorHtml;

        script.remove();
        resolve();
      };
      script.onerror = reject;
      calculator.appendChild(script);
    });
  }

  /**
   * Handles form submission by AJAX.
   *
   * Because of CORS, we cannot use `fetch`. Instead, we use the `loadScript`
   * above to eval to the new HTML.
   */
  function formHandler() {
    const form = calculator.querySelector("form");
    // Keeps the original TVC URL so we can put it back after submitting.
    const originalTVC = form.getAttribute("data-original-tvc");

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      const submitter = event.submitter;

      const formData = new FormData(form);
      const url = new URL(originalTVC, BASE_URL);
      for (const [key, value] of formData.entries()) {
        url.searchParams.set(key, value);
      }

      if (submitter.name) {
        url.searchParams.set(submitter.name, submitter.value);
      }

      loadScript(url).then(() => {
        const form = calculator.querySelector("form");
        form.setAttribute("data-original-tvc", originalTVC);
        formHandler();
      });
    }, {
      once: true,
    });
  }

  function load(calculatorID = (new URL(document.location)).searchParams.get("CALCULATORID")) {
    if (!calculatorID) return;

    BASE_URL.searchParams.set("version", Math.random());

    loadScript(BASE_URL.toString() + "&" + createQueryString())
    .then(() => {
      // Stores the original TVC URL for future AJAX calls.
      const form = calculator.querySelector("form");
      form.setAttribute("data-original-tvc", form.getAttribute("data-tvc"));
      calculator.showModal();
      formHandler();
    });
  }

  load();

  document.querySelectorAll(`a[href*="CALCULATORID="][rel~="lightbox"]`).forEach((trigger) => {
    // Handles `?` and `&` in querystring.
    const href = trigger.getAttribute("href").replace(/\/([\d]+)&/, "/$1?");
    trigger.addEventListener("click", (event) => {
      if (history.pushState) {
        event.preventDefault();
        window.history.pushState(null, "", href);
        load();
      }
    });
  });
});
