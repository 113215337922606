if (document.querySelector("[data-location]")) {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  script.async = true;
  script.defer = true;
  script.onload = () => {
    gapi.load("client", getSheetData)
  };
  document.head.appendChild(script);

  function getSheetData() {
    const SHEETAPIKEY = "AIzaSyAE-7Nmyo9EpqeXTAT0becj7wGI-sc7b_g";
    const SHEETID = "1u25CPlFZDkUxVXueS6isB8wIJWHbgBY_cnZMePy9gJg";

    let sheetData = {};

    gapi.client.init({
      "apiKey": SHEETAPIKEY
    }).then(() => {
      return gapi.client.request({
        "path": "https://sheets.googleapis.com/v4/spreadsheets/" + SHEETID + "/values/Locations!A2:Z"
      });
    }).then(response => {
      if (response.result?.values.length > 0) {
        response.result.values.forEach(sheetLocation => {
          sheetData[sheetLocation[0]] = {
            "phone": sheetLocation[1],
            "lobbyHours": sheetLocation[2],
            "driveThruHours": sheetLocation[3],
            "status": sheetLocation[4]
          }
        });
      }
    }, reason => {
      if (typeof reason === "object" && reason.result) {
        console.error && console.error("Error: " + reason.result.error.message);
      } else {
        console.error && console.error(reason);
      }
    }).then(() => {
      document.querySelectorAll("[data-location]").forEach(pageLocation => {
        let bank = pageLocation.getAttribute("data-location");
        let key = pageLocation.getAttribute("data-key");
        if (!sheetData[bank] || !sheetData[bank][key]) {
          return;
        }
        let value = sheetData[bank][key]?.replaceAll("\n", "<br />");
        let element = pageLocation.getAttribute("data-element")
          ? pageLocation.querySelector(pageLocation.getAttribute("data-element"))
          : pageLocation

        if (value) {
          element.innerHTML = value;
          pageLocation.classList.remove("hidden");
        } else {
          pageLocation.classList.add("hidden");
        }

        if (key === "status") {
          switch (value) {
            case "Closed":
              element.classList.add("bg-tertiary-4");
              element.classList.add("text-white"); // text-white");
              break;
            case "Limited Hours":
            case "Lobby: Appt Only":
            case "Drive-Thru Only":
              element.classList.add("bg-secondary-2");
              break;
            default:
              element.classList.add("bg-secondary-1");
          }
        }

        if (key === "phone" && element.tagName === "A") {
          element.setAttribute("href", `tel:${value}`);
        }
      });
    });
  }
}
