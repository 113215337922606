const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

/**
 * This module provides a generic approach to filtering items based on
 * form submissions, specifially the querystring.
 *
 * Instead of listening to "change" event on various inputs to try to
 * figure out the filters, we let the form submit and use the resulting
 * querystring as filter. The querystring provides an "AND" filter, so
 * we can just check for items against all the filters.
 *
 * This is also more flexible, as it can also let the user bookmark the
 * URL for specific filters. The form can also has "dialog" `method` that
 * only saves the form data without sending, so we can listen to "submit"
 * event to filter when we want to avoid page reload.
 */

/**
 * Filters an item list based on the current URL search parameters.
 *
 * This assumes that the items have a `data-` attribute for each search
 * parameter, and they are siblings of each other.
 *
 * The search parameters can be set either by a GET form submission, or
 * by converting FormData to URLSearchParams.
 * @param {URLSearchParams} searchParams
 */
function filter(searchParams = new URL(location.href).searchParams) {
  let itemSelector = "", filterSelector = "";
  for (const [name, value] of searchParams) {
    if (itemSelector) itemSelector += ",";
    itemSelector += `[data-${name}]`;
    if (value) {
      filterSelector += `[data-${name}*="${value}"]`;
    }

    // Sets selected value for matching inputs, including `<select>`s.
    $$(`[name=${name}]`).forEach($input => {
      $input.value = value;
    });
  }

  if (itemSelector) {
    // Toogles visibility of items based on the filter.
    const $items = $$(itemSelector);
    $items.forEach($item => {
      const hidden = !filterSelector || !$item.matches(filterSelector)
      $item.style.display = hidden ? "none" : "";
    });
  }

}

filter();

// Handles form submission with "dialog" method to filter if applied.
// Note that "submit" event is not fired when calling `form.submit()`.
// Use `form.requestSubmit()` instead.
$$(`form.filters[method="dialog"]`).forEach($form => {
  $form.addEventListener("change", function() {
    $form.requestSubmit();
  });

  $form.addEventListener("submit", event => {
    event.preventDefault();
    const searchParams = new URLSearchParams(new FormData($form));
    filter(searchParams);

    if (history.pushState) {
      const url = new URL(location.href);
      for (const [name, value] of searchParams) {
        url.searchParams.set(name, value);
      }
      window.history.pushState(null, "", url.href);
    }
  });

  window.addEventListener("popstate", (event) => {
    filter();
  });
});
