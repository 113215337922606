
window.params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

if (document.querySelector("#location-map")) {
  let map, infoWindow, myPosition, bounds, activeMarker, options, icons;
  let markers = [];
  let APIKey = document.querySelector("#location-map").getAttribute("data-api");

  options = {
    // mapId: "73d4cc3fd1732f66",
    zoom: 4,
    lat: 29,
    long: -95,
    disableDefaultUI: true,
    zoomControl: true,
    minZoom: 4,
    restriction: {
      latLngBounds: {
        north: 56,
        south: 24,
        east: -65,
        west: -126
      }
    },
    styles: [
      {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [{
            "visibility": "simplified"
          },
          {
            "lightness": 33
          },
          {
            "saturation": "0"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{
            "visibility": "simplified"
          },
          {
            "weight": "0.25"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
            "color": "#ffffff"
          },
          {
            "visibility": "simplified"
          },
          {
            "lightness": "6"
          },
          {
            "saturation": "0"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
            "visibility": "off"
          },
          {
            "weight": "0.25"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{
          "color": "#e3eed3"
        }]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels",
        "stylers": [{
            "visibility": "on"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{
            "lightness": 20
          },
          {
            "weight": "0.25"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{
            "color": "#19294c"
          },
          {
            "visibility": "on"
          },
          {
            "weight": "0.25"
          },
          {
            "lightness": "80"
          },
          {
            "saturation": "10"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
            "color": "#bdcdd3"
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{
            "color": "#ffffff"
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{
            "visibility": "on"
          },
          {
            "color": "#39b6d3"
          },
          {
            "saturation": "-1"
          },
          {
            "lightness": "50"
          }
        ]
      }
    ]
  };

  icons = {
    default: {
      url: "/images/stellar.bank/marker.svg",
      labelOrigin: {
        x: 9,
        y: 10
      }
    },
    active: {
      url: "/images/stellar.bank/marker-active.svg",
      labelOrigin: {
        x: 14,
        y: 16
      }
    },
    mine: {
      url: "/images/stellar.bank/marker-start.svg",
      labelOrigin: {
        x: 23,
        y: 22
      }
    }
  }

  // document.querySelector(".map-display")?.addEventListener("click", toggleMapDisplay);
  document.querySelector(".search-map")?.addEventListener("submit", submitForm);
  document.querySelector("#locate-me")?.addEventListener("click", htmlLocate);
  document.getElementById("map-inspector")?.addEventListener("click", mapInspectorClick);

  loadGapi();

  window.initMap = initMap;

  function loadMap() {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKey}&libraries=geometry&callback=initMap`;
    script.defer = true;
    document.head.appendChild(script);
  }

  function initMap() {
    infoWindow = new google.maps.InfoWindow();

    map = new google.maps.Map(document.getElementById("location-map"), options);
    bounds = new google.maps.LatLngBounds();

    if (typeof myLocations === "object" && myLocations.length > 0) {
      let id = 0;
      myLocations.forEach((loc) => {
        if (!loc.latitude || !loc.longitude) return;

        addMarker(loc, id++, bounds);
      });

      setTimeout(() => {
        map.fitBounds(bounds);
        map.setCenter(bounds.getCenter());
      }, 250);
    }

    if (params.zip) {
      document.querySelector(".search-map input").value = params.zip;
      setTimeout(() => {
        submitForm();
        document.getElementById("location-map").scrollIntoView(true);
      }, 400);
    } else {
      apiLocate();
    }

  }

  function addMarker(loc, id, bounds) {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(loc.latitude, loc.longitude),
      map,
      title: loc.name,
      id: id,
      icon: icons.default,
      zIndex: 1,
      active: true
    });

    marker.locationInfo = {
      name: loc.name,
      address: loc.address,
      city: loc.city,
      state: loc.state,
      postal: loc.postalCode,
      phone: loc.phone,
      email: loc.email,
      link: loc.link,
      lobbyHours: loc.lobbyHours,
      driveThruHours: loc.driveThruHours,
      bankServices: loc.bankServices.split(",").map(v => v.trim()),
      atmServices: loc.atmServices.split(",").map(v => v.trim()),
      status: loc.status
    };

    const infoString = `
  <div class="font-primary text-ms-1">
    <p class="font-bold text-ms0 mt-0">${loc.name}</p>
    <p class="mt-ms-1">${loc.address}<br />
      ${loc.city}, ${loc.state} ${loc.postalCode}</p>
    <p>
      <a href="${loc.link}">More Details</a>
    </p>
  </div>
`;

    marker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(infoString);
      infoWindow.open({
        anchor: marker,
        map: map,
        shouldFocus: true
      });
      setActiveMarker(marker);
      setInspectorBox(marker);
    });

    bounds.extend(marker.position);
    markers.push(marker);
  }

  function htmlLocate() {
    if (navigator.geolocation) {
      // Try HTML5 geolocation.
      navigator.geolocation.getCurrentPosition(
        function positionFound(position) {
          let pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          setZip(pos);
          setUserLocation(pos, true);
        },
        function positionError(error) {
          console?.log(error);
          apiLocate();
        });
    } else {
      // Browser doesn't support Geolocation
      apiLocate();
    }
  }

  function apiLocate() {
    fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${APIKey}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      let pos = new google.maps.LatLng(data.location);
      setZip(pos);
      setUserLocation(pos, true);
    })
  }

  function findNearest(pos) {
    markers.forEach(function setMarkerDistance(marker) {
      var markerDistance = google.maps.geometry.spherical.computeDistanceBetween(pos, marker.getPosition());
      marker.locationInfo.distance = getMiles(markerDistance);
    });
    markers.sort(function sortMarkers(a, b) {
      return a.locationInfo.distance - b.locationInfo.distance;
    });
    populateNearest();
  }

  function populateNearest(start) {
    const numberLocations = parseInt(document.querySelector("#location-map").getAttribute("data-count"));
    const results = isNaN(numberLocations) ? 10 : numberLocations;
    const mapInspector = document.getElementById("map-inspector");
    const locationBoxes = mapInspector.querySelector(".location-boxes");
    const enabledMarkers = markers.filter(marker => marker.active);

    let nearbyMarkers, end;
    let html = "";

    infoWindow.close();

    if (isNaN(parseInt(start))) {
      start = 0;
    } else {
      start = parseInt(start);
    }

    if (start === 0) {
      bounds = new google.maps.LatLngBounds();
      markers.forEach(marker => {
        marker.setLabel(null);
      })
    }

    end = (start + results);

    if (end > enabledMarkers.length) {
      end = markers.length;
    }

    nearbyMarkers = enabledMarkers.slice(start, end);
    nearbyMarkers.forEach(function addVisibleMarkersToMap(marker, index) {
      marker.setLabel({
        text: String(start + index + 1),
        color: "white",
        fontSize: "14px",
        fontWeight: "bold"
      });
      bounds.extend(marker.position);
    });

    bounds.extend(myPosition.position);

    setTimeout(() => {
      map.fitBounds(bounds);
    }, 1);

    document.querySelector(".no-results")?.classList.remove("no-results");

    if (nearbyMarkers.length > 0) {
      nearbyMarkers.forEach(function renderNearbyMarkerList(marker, index) {
        let info = marker.locationInfo;
        let displayNumber = start + index + 1;
        let statusClasses;

        switch (info.status) {
          case "Open":
            break;
          case "Closed":
            statusClasses = "closed";
            break;
          default:
            statusClasses = "limited";
        }

        let lobbyHours = info.lobbyHours ? ("Lobby: " + info.lobbyHours) : "";
        let driveThruHours = info.driveThruHours ? ("Drive-Thru: " + info.driveThruHours) : "";

        if (info.lobbyHours && info.driveThruHours) lobbyHours += "<br />"

        html += `
<div class="hidden location-box" data-id="${marker.id}">
  <div class="number">
    <span>${displayNumber}</span>
  </div>
  <div class="flex-grow relative">
    <div class="flex items-start">
      <h3 class="name">${info.name}</h3>
      <div class="miles">${info.distance.toFixed(1)} miles</div>
    </div>

    <div class="flex gap-ms1 items-start">
      <p class="flex-grow">${info.address}<br />
        ${info.city}, ${info.state} ${info.postal}<br />
        <a href="${getDirectionsLink(info)}" target="_blank">Get Directions</a>
      </p>
      <div class="status ${statusClasses}">${info.status}</div>
    </div>

    <h4 class="hours-header">Hours of Operation</h4>
    <p>
      ${lobbyHours}
      ${driveThruHours}
    </p>

    <p class="bottom">
      <span><a href="${contactLink}" class="email">Message Us</a></span>
      <span><a href="tel:${info.phone}" class="phone">${info.phone}</a></span>
      <span><a href="${info.link}">More Details</a></span>
    </p>

  </div>
</div>
      `;
      });

      if (end < enabledMarkers.length) {
        html += `
        <div class="my-ms1 text-center load-more">
          <button class="button" data-start=${end} id="loadMore">Load More</button>
        </div>
      `;
      }
    } else {
      html = `
      <div class="location-box">No results</div>
    `;
      map.setZoom(9);
    }

    if (start === 0) {
      locationBoxes.innerHTML = html;
    } else {
      locationBoxes.innerHTML = locationBoxes.innerHTML + html;
      locationBoxes.scrollBy({
        top: 100,
        left: 0,
        behavior: 'smooth'
      });
    }

  }

  function setUserLocation(pos, isGeo, term) {
    map.setCenter(pos);

    if (myPosition) {
      myPosition.setPosition(pos);
      myPosition.setMap(map);
    } else {
      myPosition = new google.maps.Marker({
        position: pos,
        map: map,
        id: "my-position",
        icon: icons.mine,
        zIndex: 0
      });
    }

    if (term) {
      myPosition.term = term;
    }

    map.setZoom(12);
    findNearest(pos);
  }

  function setZip(pos) {
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({ "location": pos }, function geocodePosition(results, status) {
      if (status === "OK") {
        if (results[0]) {
          results[0].address_components.forEach(function eachAddressComponent(comp) {
            if (comp.types[0] === "postal_code") {
              document.querySelector(".search-map input").value = comp.long_name;
              document.getElementById("reset-map").classList.remove("hidden");
            }
          });
        } else {
          console?.log("Could not geocode coordinates.");
        }
      } else {
        console?.log("Geocoder failed due to: " + status);
      }
    });
  }

  function getMiles(meters) {
    return Number(meters) * 0.000621371192;
  }

  function getDirectionsLink(li) {
    var url = "https://www.google.com/maps/dir/?api=1&destination=";

    url += encodeURIComponent(li.address + ", " + li.city + ", " + li.state + ", " + li.postal);
    url += "&origin=";
    if (myPosition.term) {
      url += encodeURIComponent(myPosition.term)
    } else if (myPosition) {
      url += encodeURIComponent(myPosition.getPosition().lat() + "," + myPosition.getPosition().lng());
    }
    return url;
  }

  function submitForm(e) {
    var geocoder = new google.maps.Geocoder();
    var address = document.querySelector(".search-map input").value;

    if (typeof e === "object") {
      e.preventDefault();
    }

    if (infoWindow) {
      infoWindow.close();
    }

    resetActiveMarker();

    if (address !== "") {
      geocoder.geocode({
        "address": address,
        "componentRestrictions": {
          "country": "US"
        }}, function gcc(results, status) {
        let errorMsg = document.getElementById("error-msg");
        if (status == google.maps.GeocoderStatus.OK) {
          setUserLocation(results[0].geometry.location, false, address);
          errorMsg.textContent = "";
          errorMsg.classList.add("hidden");
        } else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
          errorMsg.textContent = "No results found. Please try again.";
          errorMsg.classList.remove("hidden");
        } else {
          errorMsg.textContent = "An error has occurred. Please try a different value.";
          errorMsg.classList.remove("hidden");
          console?.log(`Geocode Status: ${status}`);
        }
      });
    } else {
      map.setZoom(options.zoom);
      map.setCenter(new google.maps.LatLng(options.lat, options.long));
    }

    document.getElementById("reset-map").classList.remove("hidden");
  }

/*   function toggleMapDisplay(event) {
    event.preventDefault();
    if (event.target.tagName !== "BUTTON") return;

    let button = event.target;

    if (!button.classList.contains("active")) {
      let sibling = button.nextElementSibling ? button.nextElementSibling : button.previousElementSibling;
      button.classList.add("active");
      sibling.classList.remove("active");

      let el = document.getElementById(event.target.getAttribute("rel"));
      sibling = el.nextElementSibling ? el.nextElementSibling : el.previousElementSibling;
      el.classList.remove("hidden");
      sibling.classList.add("hidden");
    }
  } */

  function mapInspectorClick(event) {
    let el = event.target;
    let bounds = new google.maps.LatLngBounds();

    if (el.tagName === "A") return;

    if (el.id === "loadMore") {
      let start = el.getAttribute("data-start");
      el.parentNode.remove();
      populateNearest(start);
      return;
    }

    if (!el.matches('.location-box')) {
      el = el.closest(".location-box");
    }

    if (!el || el.classList.contains("active")) return;

    event.preventDefault();

    let markerId = parseInt(el.getAttribute("data-id"));

    let chosenMarker = markers.find(obj => { return obj.id === markerId });

    bounds.extend(chosenMarker.position);
    bounds.extend(myPosition.position);

    map.fitBounds(bounds);

    google.maps.event.trigger(chosenMarker, 'click', {
      latLng: new google.maps.LatLng(0, 0)
    });
  }

  function setActiveMarker(marker) {
    if (activeMarker && activeMarker !== marker) {
      activeMarker.setIcon(icons.default);
      activeMarker.setZIndex(1);
      changeMarkerFontSize(activeMarker, "14px");
    }

    activeMarker = marker;

    activeMarker.setIcon(icons.active);
    activeMarker.setZIndex(10);
    changeMarkerFontSize(activeMarker, "18px");
  }

  function setInspectorBox(marker) {
    let box = document.querySelector(`.location-box[data-id="${marker.id}"]`);

    if (!box) return;

    if (!box.classList.contains("active")) {
      document.querySelectorAll(".location-box.active").forEach((box) => box.classList.remove("active"));
      box.classList.add("active");
      document.querySelector("#map-inspector").scrollTo({
        top: box.offsetTop,
        behavior: "smooth"
      });
    }
  }

  function changeMarkerFontSize(marker, size) {
    if (!marker.getLabel()) return;

    let label = marker.getLabel();
    label.fontSize = size;
    marker.setLabel(label);
  }

  function resetActiveMarker() {
    if (activeMarker) {
      activeMarker.setIcon(icons.default);
      activeMarker.setZIndex(1);
      changeMarkerFontSize(activeMarker, "14px");
    }
  }

  /*  Filter */
  if (document.querySelector(".map-controls")) {
    document.querySelectorAll(".fake-select")?.forEach((fakeSelect) => {
      fakeSelect.addEventListener("click", openFilterBox);
    });
    document.querySelectorAll(".filter-close")?.forEach((fakeSelect) => {
      fakeSelect.addEventListener("click", closeFilterBox);
    });
    document.getElementById("reset-map")?.addEventListener("click", resetMap);
    document.querySelectorAll("form.map-controls").forEach((form) => {
      form.addEventListener("reset", (event) => {
        setTimeout(() => {
          form.querySelectorAll(".fake-select .count").forEach(el => el.innerText = "");
          applyFilters(form);
        }, 0);
      });
    });
  }

  function openFilterBox(event) {
    event.preventDefault();
    let filterBox = this.parentElement.querySelector(".filters-box");
    document.querySelectorAll(".filters-box").forEach((element) => { element.classList.add("hidden") });
    filterBox.classList.remove("hidden");
    filterBox.querySelectorAll("input[type='checkbox']")?.forEach(checkBox => {
      checkBox.prevState = checkBox.checked;
    });
  }

  function closeFilterBox(event) {
    event.preventDefault();
    let button = this;
    let box = button.closest(".filters-box");
    let select = box.parentNode.querySelector(".fake-select");
    let selectedCheckboxes = box.querySelectorAll("input:checked");

    if (button.classList.contains("cancel")) {
      box.querySelectorAll("input[type='checkbox']")?.forEach(checkBox => {
        checkBox.checked = checkBox.prevState;
        delete checkBox.prevState;
      });
      box.classList.add("hidden");
      return;
    }

    if (selectedCheckboxes.length > 0) {
      select.querySelector(".count").innerText = `(${selectedCheckboxes.length})`;
    } else {
      select.querySelector(".count").innerText = "";
    }
    applyFilters(this.closest("form"));
    box.classList.add("hidden");
  }

  function applyFilters(form) {
    let formData = new FormData(form);
    let formObj = serialize(formData);

    markers.map(marker => marker.active = true);

    if (Object.keys(formObj).length) {
      markers.map(marker => {
        let active = false;
        for (key in formObj) {
          if (marker?.locationInfo[key] && marker.locationInfo[key].some(v => formObj[key].includes(v))) {
            active = true;
            return;  // Exit the loop if even one is active
          }
        }
        marker.active = active;
        return marker;
      });
    }

    populateNearest();
  }

  function resetMap(event) {
    let button = event.target;
    event.preventDefault();
    document.querySelector(".location-boxes").innerHTML = "";
    document.getElementById("map-container")?.classList.add("no-results");
    button.closest("form").reset();
    button.classList.add("hidden");
    infoWindow?.close();
  }

  function serialize(data) {
    let obj = {};
    for (let [key, value] of data) {
      if (obj[key] !== undefined) {
        obj[key].push(value);
      } else {
        obj[key] = [value];
      }
    }
    return obj;
  }

  /* Location data from google sheets */
  function loadGapi() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    // script.defer = true;
    script.onload = () => {
      gapi.load("client", getSheetData)
    };
    document.head.appendChild(script);
  }

  function getSheetData() {
    const SHEETAPIKEY = "AIzaSyAE-7Nmyo9EpqeXTAT0becj7wGI-sc7b_g";
    const SHEETID = "1u25CPlFZDkUxVXueS6isB8wIJWHbgBY_cnZMePy9gJg";

    gapi.client.init({
      "apiKey": SHEETAPIKEY
    }).then(() => {
      return gapi.client.request({
        "path": "https://sheets.googleapis.com/v4/spreadsheets/" + SHEETID + "/values/Locations!A2:Z"
      });
    }).then(response => {
      if (response.result?.values.length > 0) {
        response.result.values.forEach(location => {
          let thisLocation = myLocations.find(m => m.name.includes(location[0]));
          if (thisLocation) {
            thisLocation.phone = location[1];
            thisLocation.lobbyHours = shortenHours(location[2]);
            thisLocation.driveThruHours = shortenHours(location[3]);
            thisLocation.status = location[4];
          }
        });
        loadMap();
      }
    }, reason => {
      if (typeof reason === "object" && reason.result) {
        console.error && console.error("Error: " + reason.result.error.message);
      } else {
        console.error && console.error(reason);
      }
    });
  }

  function shortenHours(hours) {
    let newHours;
    newHours = hours.replaceAll("Monday","Mon");
    newHours = newHours.replaceAll("Tuesday", "Tue");
    newHours = newHours.replaceAll("Wednesday", "Wed");
    newHours = newHours.replaceAll("Thursday", "Thu");
    newHours = newHours.replaceAll("Friday", "Fri");
    newHours = newHours.replaceAll("Saturday", "Sat");
    newHours = newHours.replaceAll("Sunday", "Sun");
    newHours = newHours.replaceAll(":00 ", "");
    newHours = newHours.replaceAll(":30 ", ":30");
    return newHours;
  }
}
